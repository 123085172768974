/* eslint-disable */
import React from 'react';

const FacebookIcon = () => {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6791 0H7.98606C6.79567 0 5.65404 0.472879 4.81231 1.31461C3.97058 2.15634 3.4977 3.29797 3.4977 4.48836V7.18137H0.804688V10.7721H3.4977V17.9534H7.08839V10.7721H9.7814L10.6791 7.18137H7.08839V4.48836C7.08839 4.25028 7.18296 4.02195 7.35131 3.85361C7.51965 3.68526 7.74798 3.59069 7.98606 3.59069H10.6791V0Z" fill="white"/>
    </svg>
  );
};

// eslint-disable-next-line import/no-default-export
export default FacebookIcon;
